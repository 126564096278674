import { Inject, Injectable } from '@angular/core';

export const NEWRELIC_CONFIGURATION = 'NEWRELIC_CONFIGURATION';

export interface NewrelicConfiguration {
	isEnabled: boolean;
	accountId: string;
	trustKey: string;
	agentId: string;
	applicationId: string;
	licenseKey: string;
	assetDomain: string;
	beaconDomain: string;
}
@Injectable({
	providedIn: 'root',
})
export class NewrelicService {
	browserAgent: any;

	constructor(
		@Inject(NEWRELIC_CONFIGURATION)
		configuration: NewrelicConfiguration,
	) {
		if (configuration.isEnabled) {
			(async () => {
				const { BrowserAgent } = await import('@newrelic/browser-agent/loaders/browser-agent');
				this.browserAgent = new BrowserAgent({
					init: {
						distributed_tracing: { enabled: true },
						privacy: { cookies_enabled: true },
						ajax: { deny_list: [configuration.beaconDomain] },
						proxy: {
							assets: configuration.assetDomain,
							beacon: configuration.beaconDomain,
						},
					},

					info: {
						beacon: configuration.beaconDomain,
						errorBeacon: configuration.beaconDomain,
						licenseKey: configuration.licenseKey,
						applicationID: configuration.applicationId,
						sa: 1,
					},
					loader_config: {
						accountID: configuration.accountId,
						trustKey: configuration.trustKey,
						agentID: configuration.agentId,
						licenseKey: configuration.licenseKey,
						applicationID: configuration.applicationId,
					},
				});
			})();
		}
	}
}
