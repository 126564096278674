<button mat-menu-item>
	<mat-slide-toggle class="flex justify-center" [checked]="isDarkMode()" (change)="toggleTheme()">
		<div class="text-slate-900 dark:text-white">Dark Mode</div>
	</mat-slide-toggle>
</button>
<button mat-menu-item routerLink="{{ getHomeRouterLink() }}"><mat-icon>home</mat-icon>Home</button>
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/my-playlists">
		<mat-icon>featured_play_list</mat-icon>
		My Playlists
	</button>
}
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/search">
		<mat-icon>search</mat-icon>
		Search
	</button>
}
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/release-search">
		<mat-icon>manage_search</mat-icon>
		Advanced Search
	</button>
}
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/music-tracks">
		<mat-icon>music_note</mat-icon>
		Music
	</button>
}
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/choreography">
		<mat-icon>sports_gymnastics</mat-icon>
		Choreography
	</button>
}
@if (
	userInfo$().isAdministrator ||
	(userInfo$().hasActiveSubscriptionOrTrial &&
		(userInfo$().isInstructor || userInfo$().isProgramAdmin || userInfo$().isProgramManager))
) {
	<button mat-menu-item routerLink="/instructor/releases">
		<mat-icon>assignment</mat-icon>
		Releases
	</button>
}
@if (userInfo$().isAuthenticated) {
	<button mat-menu-item routerLink="/instructor/account-info">
		<mat-icon>account_circle</mat-icon>
		Account
	</button>
}
<a [href]="marketingSiteUrl + '/recent-changes'">
	<button mat-menu-item>
		<mat-icon>new_releases</mat-icon>
		What's New
	</button>
</a>
<a [href]="marketingSiteUrl + '/pricing'">
	<button mat-menu-item routerLink="/pricing">
		<mat-icon>price_check</mat-icon>
		GFX Pricing
	</button>
</a>
<a [href]="marketingSiteUrl + '/about'">
	<button mat-menu-item routerLink="/about">
		<mat-icon>question_mark</mat-icon>
		About
	</button>
</a>

@if (userInfo$().isAdministrator || userInfo$().isProgramAdmin || userInfo$().isProgramManager) {
	<mat-divider></mat-divider>
	<button mat-menu-item routerLink="/program-manager/master-tracks">
		<mat-icon>music_note</mat-icon>
		Master Tracks
	</button>

	<button mat-menu-item routerLink="/program-manager/exercises">
		<mat-icon>directions_run</mat-icon>
		Exercises
	</button>
	<button mat-menu-item routerLink="/program-manager/artists">
		<mat-icon>color_lens</mat-icon>
		Artists
	</button>
	<button mat-menu-item routerLink="/program-manager/songs">
		<mat-icon>music_note</mat-icon>
		Songs
	</button>
	<button mat-menu-item routerLink="/program-manager/release-import">
		<mat-icon>upload</mat-icon>
		Release Import
	</button>
}
@if (userInfo$().isProgramAdmin || userInfo$().isAdministrator) {
	<button mat-menu-item routerLink="/program-admin/programs">
		<mat-icon>assignment</mat-icon>
		Programs
	</button>
}
@if (userInfo$().isProgramAdmin || userInfo$().isAdministrator) {
	<button mat-menu-item routerLink="/program-admin/tags">
		<mat-icon>sell</mat-icon>
		Tags
	</button>
}
@if (userInfo$().isProgramManager || userInfo$().isProgramAdmin || userInfo$().isAdministrator) {
	<button mat-menu-item routerLink="/program-manager/releases">
		<mat-icon>assignment</mat-icon>
		Releases
	</button>
	<button mat-menu-item routerLink="/admin/users">
		<mat-icon>person</mat-icon>
		Users
	</button>
}
@if (userInfo$().isAdministrator) {
	<button mat-menu-item routerLink="/admin/admin-console">
		<mat-icon>engineering</mat-icon>
		Admin Console
	</button>
}
@if (userInfo$().isAuthenticated) {
	<div>
		<mat-divider></mat-divider>
		<button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon> Log Out</button>
	</div>
}
