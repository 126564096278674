import { Component, OnInit, signal } from '@angular/core';
import { SubscriptionService } from '../../services/subscriptions/subscription.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ISubscriptionReadModel } from '../../services/subscriptions/models/subscription.types';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { DateTime } from 'luxon';
import { UserStoreService } from '../../services/stores/user-store.service';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-create-subscription',
	standalone: true,
	templateUrl: './create-subscription.component.html',
	styleUrls: ['./create-subscription.component.scss'],
	imports: [MatButtonModule, MatIconModule, MatRadioModule, MatProgressSpinnerModule, RouterModule],
})
export class CreateSubscriptionComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private userStoreService: UserStoreService,
		private subscriptionService: SubscriptionService,
		private router: Router,
		private sanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
	) {
		this.marketingSiteUrl = environment.marketingSiteUrl;
	}
	subscription: ISubscriptionReadModel | undefined;
	iframeUrl: SafeResourceUrl | undefined;
	continueToCheckout$ = signal(false);
	isIframeReady$ = signal(false);
	hasActiveSubscription$ = signal(false);
	nextBillCycleDate$ = signal('');
	selectedPlan: 'monthly' | 'yearly' = 'monthly';
	marketingSiteUrl: string;

	async ngOnInit(): Promise<void> {
		const queryParams = this.activatedRoute.snapshot.queryParams;
		const hostedpageonly = queryParams['hostedpageonly'] as boolean;
		if (hostedpageonly) {
			await this.getHostedPaymentPage();
			return;
		}

		const user = await this.authService.createAccount();
		if (!user || !user.isAuthenticated) {
			console.error('Unable to create account');
			this.router.navigate(['/login']);
			return;
		}

		await this.userStoreService.reinitialize();

		this.subscription = await this.subscriptionService.getSubscription();
		if (this.userStoreService.userData$().hasActiveSubscription()) {
			this.hasActiveSubscription$.set(true);
			const nextBillingCycleDate = this.userStoreService
				.userData$()
				.getUserData()
				?.getSubscriptionModel()
				?.getNextBillingCycleDateUtc();
			this.nextBillCycleDate$.set(DateTime.fromISO(nextBillingCycleDate!).toLocaleString());
			this.isIframeReady$.set(false);
		}
	}

	async getHostedPaymentPage(): Promise<void> {
		this.continueToCheckout$.set(true);
		const results = await this.subscriptionService.getHostedPaymentPage(this.selectedPlan);

		this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(results.url);
	}

	iframeLoaded(): void {
		this.isIframeReady$.set(true);
	}

	changePlanFrequency($event: MatRadioChange): void {
		this.selectedPlan = $event.value;
	}
}
