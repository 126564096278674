<div class="max-w-2xl p-2">
	<h2>Recent Changes at GFX</h2>

	<div class="flex flex-col gap-4">
		<div>
			<h4 class="mb-0">July 28, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Updated the image that shows when sharing a playlist to be the instructor's profile picture with a GFX overlay
				instead of only the GFX logo.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">June 30, 2024</h4>
			<p class="text-sm dark:text-slate-300">Upgraded the user interface framework for a fresh look and feel.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">June 23, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Updated the format of the shared playlists in the community feed on the instructor home page to make the most
				important information more apparent and remove some of the clutter.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">June 19, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added the ability to tag a release track with helpful keywords or phrases to make them easier to find. I've seen
				so many requests on social media where instructors are looking for a track and they can't quite remember what
				release it is, but they know something about it. Release track tagging was created just for this.
			</p>
			<p class="text-sm dark:text-slate-300">
				Added ability to search by both playlist and release
				<a [routerLink]="['/instructor/tags']" class="underline">tags</a>. You can navigate there from the landing page,
				or just tap any playlist or release track tags.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">June 16, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added a helpful group of destinations to the instructor landing page. It shows on the right when in desktop
				mode, or at the top when in mobile mode.
			</p>
			<p class="text-sm dark:text-slate-300">
				Added ability to search by specific
				<a [routerLink]="['/instructor/tags']" class="underline">playlist tags</a>. You can navigate there from the
				landing page, or just tap any playlist in the feed that already has a tag.
			</p>
			<p class="text-sm dark:text-slate-300">
				Playlist tags are a great way to identify your playlist by a theme. Maybe it's Halloween, Valentine's Day, Rock
				And Roll, or anything in between. It makes it easy for other instructors to find your playlist. If you're
				familiar with hashtags in social media, it works much the same.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">May 25, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added ability to share an entire release on social media. You can also link to an individual track to make it
				easier to share with others. Go to
				<a [routerLink]="['/instructor/releases']" class="underline">releases</a> and then choose a specific release.
				Look for the <span class="inline-flex align-middle"><mat-icon>ios_share</mat-icon></span> icon on the release
				header and the <span class="inline-flex align-middle"><mat-icon>link</mat-icon></span> icon on the individual
				tracks.
			</p>
			<p class="text-sm dark:text-slate-300">
				We've also been hard at work behind the scenes with some changes that improve the load time and overall
				application stability.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">April 27, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added the ability to properly identify different types of tracks that occupy the same track number. For example,
				BALANCE warm-up tracks can now indicate whether they are Tai Chi or Yoga.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">April 19, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Search auto-complete now suggests playlists, release tracks, or instructors and visually indicates which type it
				is.
			</p>
			<p class="text-sm dark:text-slate-300">Added a footer for quicker navigation.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">April 4, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added ability to copy from an existing release when creating a new playlist.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">March 24, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Added ability to upload your choreography and associate it to a release. Then, when building your playlist, you
				can see the choreography next to the tracks you're choosing.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">March 11, 2024</h4>
			<p class="text-sm dark:text-slate-300">Added page for viewing playlists that match tags.</p>
			<p class="text-sm dark:text-slate-300">Added quick actions component on instructor home.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">March 10, 2024</h4>
			<p class="text-sm dark:text-slate-300">Added ability to add descriptive tags to playlists.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">March 2, 2024</h4>
			<p class="text-sm dark:text-slate-300">Backend work to prepare for a new program.</p>
			<p class="text-sm dark:text-slate-300">Updated the look and feel of the Playlist Planner.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">February 23, 2024</h4>
			<p class="text-sm dark:text-slate-300">Added checklist for setting up user profiles.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">February 21, 2024</h4>
			<p class="text-sm dark:text-slate-300">Added ability to filter the social feed by your program preferences.</p>
			<p class="text-sm dark:text-slate-300">
				The advanced search automatically pre-filters the programs and releases based up on user preferences.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">February 10, 2024</h4>
			<p class="text-sm dark:text-slate-300">
				Landing page dynamically loads more recently shared playlists as you scroll.
			</p>
			<p class="text-sm dark:text-slate-300">Better handling of subscription information on the server side.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">January 27, 2024</h4>
			<p class="text-sm dark:text-slate-300">New users get a 14-day trial when creating an account.</p>
			<p class="text-sm dark:text-slate-300">
				Updated the way subscriptions are handled to account for trial mode. Creating a subscription before the end of
				the trial period will create a future subscription so that you keep all of the trial days.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h4 class="mb-0">December 18, 2023</h4>
			<p class="text-sm dark:text-slate-300">Changes to the theme to make it easier to use in dark mode.</p>
			<p class="text-sm dark:text-slate-300">Added profile summary to the landing page for desktop view.</p>
			<p class="text-sm dark:text-slate-300">
				The landing page recent playlists are now sourced from the search index.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>December 6, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Revamped search in a pretty massive way. The behind-the-scenes search functionality has been massively
				overhauled, but the results should be essentially the same. The change involves "Quick Search", which now
				returns matches on instructor, release track, or instructor playlists. Previously, the search would only locate
				release tracks.
			</p>
			<p class="text-sm dark:text-slate-300">
				Due to the changes made to search, the initial loadtime of the website should be faster now when first logging
				in and landing at the instructor home page.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>October 25, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Added ability to create an instructor profile and allow it to be shared with others or not. This can be found
				under the Profile section in your Account.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>October 10, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Added a Playlist Description to provide additional context when sharing your playlist with fellow instructors
				and gym members.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>October 9, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Added a component that lets you crop and adjust your profile picture when selecting it from your account page.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>September 18, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added ability to upload a profile picture in Account Settings.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>September 17, 2023</h3>
			<p class="text-sm dark:text-slate-300">Reworked side navigation to make the alignment cleaner.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 21st, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Added a rating system on the release tracks, available in the search results for the time being. Can rate 1-5
				stars and it averages based upon community votes.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 20th, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Re-styled the landing page to have tabs for Community, My Playlists, and Recent Releases.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 17th, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				The exercises on the advanced search page now use autocomplete to make finding the exercises easier. Also
				ability to specify exercises to exclude from the advanced search page.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 15th, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				The artist name and song name fields in the advanced search now use fuzzy matching. For example, "fallout"
				matches "Fall Out Boy" and so on.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 14th, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added ability to like a community playlist.</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 5th, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Searching from the toolbar is now much better at finding fuzzy matches in the tracks. For example, "pink" now
				finds the artist "P!nk". And searching for "squats" now shows tracks that have "Squat" as an exercise.
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>August 4th, 2023</h3>
			<p class="text-sm dark:text-slate-300">
				Added new background images. Still a work in progress. Check out both light and dark mode!
			</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>July 29, 2023</h3>
			<p class="text-sm dark:text-slate-300">Add ability to copy from community playlist to your own</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>July 26, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added recent community releases to instructor home page</p>
			<p class="text-sm dark:text-slate-300">Added ability to cancel and reactivate a subscription</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>July 25, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added buttons to share playlists on Facebook and Twitter</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>July 23, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added ability to rename playlist from the playlist planner</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
		<div>
			<h3>July 14, 2023</h3>
			<p class="text-sm dark:text-slate-300">Added ability to lock/unlock/shuffle tracks on playlist planner</p>
			<mat-divider class="my-4"></mat-divider>
		</div>
	</div>
</div>
