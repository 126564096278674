import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IScrollService } from './scroll.interface.service';

@Injectable({
	providedIn: 'root',
})
export class ClientScrollService implements IScrollService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	scrollToElement(elementId: string): void {
		const element = this.document.querySelector(`#${elementId}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
}
