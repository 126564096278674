import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InstructorReleasePlaylistService } from '../instructor-release-playlist/instructor-release-playlist.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyPlaylistService } from '../my-playlist/my-playlist.service';
import { UserStoreService } from '../stores/user-store.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PlaylistDialogComponent } from '../../modules/instructor/components/playlist-dialog/playlist-dialog.component';

@Injectable()
export class CreatePlaylistService {
	constructor(
		private dialog: MatDialog,
		private userStoreService: UserStoreService,
		private releasePlaylistService: InstructorReleasePlaylistService,
		private router: Router,
		private snackbar: MatSnackBar,
		private myPlaylistService: MyPlaylistService,
	) {}

	async createPlaylist(): Promise<void> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			programId: undefined,
			programFormatId: undefined,
			playlistName: undefined,
			newPlaylist: true,
		};
		const dialogRef = this.dialog.open(PlaylistDialogComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(async (result) => {
			if (result === undefined) {
				return;
			}

			if (result.sourceReleaseId !== '') {
				var newPlaylist = await this.releasePlaylistService.copyReleaseToPlaylist(
					result.playlistName,
					result.playlistDescription,
					result.sourceReleaseId,
				);

				const snackbarRef = this.snackbar.open(`"${result.playlistName}" playlist created`, 'Go To Playlist', {
					duration: 5000,
				});
				this.myPlaylistService.signalMyPlaylistChanged(newPlaylist.playlistName);

				snackbarRef.onAction().subscribe(() => {
					this.router.navigate(['/instructor/playlist-planner', newPlaylist.instructorReleasePlaylistId]);
				});
			} else {
				const playlistResult = await this.releasePlaylistService.createReleasePlaylist({
					programFormatId: result.programFormat,
					playlistName: result.playlistName,
					playlistDescription: result.playlistDescription,
				});
				const snackbarRef = this.snackbar.open(`"${result.playlistName}" playlist created`, 'Go To Playlist', {
					duration: 5000,
				});
				this.myPlaylistService.signalMyPlaylistChanged(playlistResult.playlistName);

				snackbarRef.onAction().subscribe(() => {
					this.router.navigate(['/instructor/playlist-planner', playlistResult.instructorReleasePlaylistId]);
				});
			}
		});
	}
}
