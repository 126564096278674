import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserStoreService } from '../../services/stores/user-store.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private userStoreService: UserStoreService,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {}

	async ngOnInit(): Promise<void> {
		if (isPlatformBrowser(this.platformId)) {
			await this.userStoreService.initialize();
			const user = await this.authService.createAccount();
			if (!user || !user.isAuthenticated) {
				console.error('Unable to create account');
				this.router.navigate(['/login']);
				return;
			}

			await this.userStoreService.reinitialize();

			this.router.navigate(['/instructor/home']);
		}
	}
}
