import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
	constructor(
		@Inject('API_URL') private baseUrl: string,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			tap({
				error: (e) => {
					if (e.status === 401) {
						if (isPlatformBrowser(this.platformId)) {
							window.location.assign(`${this.baseUrl}/api/account/login?returnUrl=${window.location.href}`);
						} else {
							console.error('Unauthorized');
						}
					}
				},
			}),
		);
	}
}
