import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CanonicalLinkService {
	constructor(@Inject(DOCUMENT) private dom: Document) {}

	updateCanonicalUrl(url: string) {
		const head = this.dom.getElementsByTagName('head')[0];
		var element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;
		if (element == null) {
			element = this.dom.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', 'canonical');
		element.setAttribute('href', url);
	}
}
