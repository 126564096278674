<div *ngIf="loading$()" class="max-w-xl mx-auto">
	<p>Updating subscription details...</p>
	<div class="flex justify-center">
		<mat-spinner></mat-spinner>
	</div>
</div>
<div *ngIf="!loading$()" class="max-w-xl mx-auto">
	<img src="../../../assets/images/gfx-heartbeat.webp" alt="GFX Logo" class="w-32 mx-auto mb-4" />
	<h3 class="text-center">
		Success! You are now subscribed to GFX. There are some helpful items on the homepage to help you jump in and get
		started.
	</h3>
	<div class="mx-auto flex justify-evenly mt-8">
		<button class="ml-4" mat-flat-button routerLink="/instructor/home">Go To My Homepage</button>
	</div>
</div>
