import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class WaitListService {
	constructor(private http: HttpClient) {}

	joinWaitList(emailAddress: string): Observable<string> {
		return this.http.post<string>('/api/home/join-waitlist', { emailAddress: emailAddress });
	}
}
