<mat-toolbar class="bg-gfx-primary" role="navigation">
	<!-- Set the width to constant here and in the right-most span so that
  the menu bar text is centered in the page, as opposed to centered
  in the remaining gap between the left-most and right-most content -->
	<span class="w-20 flex items-center gap-2 mr-2">
		<button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="GFX Menu" aria-label="GFX Menu">
			<mat-icon class="text-white">menu</mat-icon>
		</button>
		<button
			mat-mini-fab
			(click)="performHomeAction()"
			aria-label="Side navigation menu"
			matTooltip="GFX Home"
			class="bg-gfx-accent">
			<img src="/assets/images/gfx-heartbeat.webp" alt="GFX Logo" />
		</button>
	</span>

	@if (userInfo$()?.isAuthenticated && !isOnLoginPage$()) {
		<span class="flex-auto text-center text-white">Group Fitness X</span>
	} @else {
		<span class="flex-auto"></span>
	}

	@if (!userInfo$()?.isAuthenticated && !isOnLoginPage$()) {
		<span class="flex items-center">
			<button
				mat-flat-button
				[disabled]="userInfo$()?.isInitializing"
				class="gfx-button-secondary text-slate-900"
				routerLink="/login">
				Sign In / Create Account
			</button>
		</span>
	}

	@if (!userInfo$()?.isInitializing && userInfo$()?.isAuthenticated) {
		<span class="w-20 flex flex-row-reverse items-center">
			<button
				mat-mini-fab
				class="gfx-button-primary"
				[matMenuTriggerFor]="userMenu"
				matTooltip="User Menu"
				aria-label="User Menu">
				<span class="flex flex-row align-middle">
					<img class="rounded-lg" src="{{ getUserProfilePictureThumbnailUrl() }}" alt="User Profile Picture" />
				</span>
			</button>
		</span>
	}
</mat-toolbar>

<mat-menu #appMenu="matMenu"><app-menu></app-menu></mat-menu>

<mat-menu #userMenu="matMenu" xPosition="before">
	<button mat-menu-item routerLink="/instructor/account-info">
		<mat-icon>settings</mat-icon>
		Account Settings
	</button>
	<button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon> Log Out</button>
</mat-menu>
