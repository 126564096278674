import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
	constructor(meta: Meta) {
		meta.updateTag({ property: 'og:title', content: 'Group Fitness X' });
		meta.updateTag({ property: 'og:description', content: 'Group Fitness Instructor Portal' });
		meta.updateTag({ property: 'og:image', content: `https://groupfitx.com/assets/images/gfx-heartbeat.webp` });
		meta.updateTag({ property: 'og:url', content: 'https://groupfitx.com' });
		meta.updateTag({ property: 'og:type', content: 'website' });
	}
}
