import { Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import {
	authCanActivateFn,
	programAdminCanActivateFn,
	programManagerCanActivateFn,
	sysAdminCanActivateFn,
} from './route-guards/route-guard.functions';
import { CreateSubscriptionComponent } from './components/create-subscription/create-subscription.component';
import { SubscribeSuccessComponent } from './components/subscribe-success/subscribe-success.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { RecentChangesComponent } from './components/recent-changes/recent-changes.component';
import { GfxPricingComponent } from './components/gfx-pricing/gfx-pricing.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AccountRemovedComponent } from './components/account-removed/account-removed.component';

export const AppRoutes: Routes = [
	{
		path: 'admin',
		title: 'GFX System Administrator',
		loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
		canActivate: [authCanActivateFn, sysAdminCanActivateFn],
	},
	{
		path: 'program-admin',
		title: ' GFX Program Administrator',
		loadChildren: () => import('./modules/program-admin/program-admin.module').then((m) => m.ProgramAdminModule),
		canActivate: [authCanActivateFn, programAdminCanActivateFn],
	},
	{
		path: 'program-manager',
		title: 'GFX Program Manager',
		loadChildren: () => import('./modules/program-manager/program-manager.module').then((m) => m.ProgramManagerModule),
		canActivate: [authCanActivateFn, programManagerCanActivateFn],
	},
	{
		path: 'instructor',
		title: 'GFX Instructor Portal',
		loadChildren: () => import('./modules/instructor/instructor.module').then((m) => m.InstructorModule),
		canActivate: [authCanActivateFn],
	},
	{
		path: 'community',
		title: 'GFX Community Portal',
		loadChildren: () => import('./modules/community/community.module').then((m) => m.CommunityModule),
	},
	{ path: 'login', title: 'Log In To GFX', component: LoginComponent, data: { canonical: '/login' } },
	{ path: 'about', title: 'About Group Fitness X', component: AboutComponent, data: { canonical: '/about' } },
	{ path: 'login', title: 'GFX Login', component: LoginComponent },
	{ path: 'create-account', title: 'Create GFX Account', component: CreateAccountComponent },
	{ path: 'create-subscription', title: 'Create GFX Subscription', component: CreateSubscriptionComponent },
	{ path: 'subscribe-success', title: 'Subscription Success', component: SubscribeSuccessComponent },
	{
		path: 'recent-changes',
		title: 'GFX Recent Changes',
		component: RecentChangesComponent,
		data: { canonical: '/recent-changes' },
	},
	{
		path: 'pricing',
		title: 'GFX Pricing',
		component: GfxPricingComponent,
		data: { canonical: '/pricing' },
	},
	{
		path: 'account-removed',
		title: 'Account Removed',
		component: AccountRemovedComponent,
	},
	{
		path: 'not-found',
		title: 'Page Not Found',
		component: NotFoundComponent,
	},
	{ path: 'home', title: 'Group Fitness X Home', component: HomeComponent, data: { canonical: '' } },
	{ path: '', title: 'Group Fitness X Home', component: HomeComponent, data: { canonical: '' } },
	{
		path: '**',
		redirectTo: '/not-found',
	},
];
