import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const REQUEST = new InjectionToken<Request>('REQUEST');

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor(@Inject('API_URL') private baseUrl: string) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const url = `${this.baseUrl}${request.url}`;

		const clonedRequest = request.clone({
			url: url,
			headers: request.headers.set('X-Requested-With', 'XMLHttpRequest'),
			withCredentials: true,
		});

		return next.handle(clonedRequest);
	}
}
