<div class="max-w-xl mx-auto">
	@if (hasActiveSubscription$()) {
		<div>
			<h3 class="max-w-xl mx-auto">
				You already have an active subscription. <mat-icon class="text-green-500">check_circle_outline</mat-icon>
			</h3>
			<h3 class="max-w-xl mx-auto">Your next billing date is {{ nextBillCycleDate$() }}.</h3>
			<p class="max-w-xl mx-auto"><button mat-flat-button routerLink="/instructor/home">Back To Home</button></p>
		</div>
	}

	@if (!hasActiveSubscription$()) {
		@if (!continueToCheckout$()) {
			<div class="text-xl mx-auto mb-4">
				<h3>
					GFX requires a subscription to access the majority of its features. You are able to continue on the site
					without a subscription, but the functionality is limited.
				</h3>
			</div>
			<div class="mx-auto flex flex-col gap-4">
				<h2 class="text-gfx-primary font-bold text-lg">Subscription Benefits</h2>
				<h3 class="text-gfx-primary dark:text-gfx-accent font-bold">Create and Manage Class Playlists</h3>
				<p class="ml-6">Create from a previous release, take inspiration from the community, or create your own</p>
				<h3 class="text-gfx-primary dark:text-gfx-accent font-bold">Find Release Tracks</h3>
				<p class="ml-6">Search by song, music artist, exercises, and more</p>
				<h3 class="text-gfx-primary dark:text-gfx-accent font-bold">Share Your Playlists</h3>
				<p class="ml-6">Easily post your class playlists on social media to keep your class participants up to date</p>
			</div>
			<fieldset class="border-2 border-gfx-primary dark:border-gfx-accent rounded-lg my-4 p-2">
				<legend class="p-2">Choose your plan</legend>
				<mat-radio-group [value]="selectedPlan" (change)="changePlanFrequency($event)">
					<div class="flex flex-row gap-4">
						<mat-radio-button value="monthly" (change)="selectedPlan = 'monthly'">Monthly - $2/month</mat-radio-button>
						<mat-radio-button value="yearly" (change)="selectedPlan = 'yearly'">Yearly - $20/year</mat-radio-button>
					</div>
				</mat-radio-group>
			</fieldset>
			<div class="flex justify-center items-center">
				<button mat-flat-button color="accent" class="mx-auto" (click)="getHostedPaymentPage()">Subscribe</button>
			</div>
		}

		@if (continueToCheckout$() && !isIframeReady$()) {
			<div class="mx-auto">
				<p>Loading subscription and payment information. Just one moment.</p>
				<div class="flex justify-center">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		}
		@if (iframeUrl) {
			<div class="w-full h-full grid grid-cols-1 gap-8">
				<iframe
					[src]="iframeUrl"
					width="100%"
					height="600px"
					frameborder="0"
					scrolling="no"
					allowtransparency="true"
					class="mx-auto col-span-1"
					(load)="iframeLoaded()"></iframe>
				<div class="max-w-lg mx-auto col-span-1">
					<p>Please see our <a [href]="marketingSiteUrl + '/terms'" class="underline">terms and conditions</a>.</p>
					<div class="flex justify-center items-center">
						<button class="m-4" mat-flat-button [routerLink]="['/instructor/home']">Cancel</button>
					</div>
				</div>
			</div>
		}
	}
</div>
