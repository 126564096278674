import { Component, afterNextRender, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { IProcessHostedPaymentPageRequest } from '../../services/subscriptions/models/subscription.types';
import { firstValueFrom } from 'rxjs';
import { SubscriptionService } from '../../services/subscriptions/subscription.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { UserStoreService } from '../../services/stores/user-store.service';

@Component({
	selector: 'app-subscribe-success',
	standalone: true,
	templateUrl: './subscribe-success.component.html',
	styleUrls: ['./subscribe-success.component.scss'],
	imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, RouterModule],
})
export class SubscribeSuccessComponent {
	constructor(
		private userStoreService: UserStoreService,
		private subscriptionService: SubscriptionService,
		private route: ActivatedRoute,
	) {
		afterNextRender(async () => {
			this.loading$.set(true);

			await this.initialize();
			this.loading$.set(false);
		});
	}
	loading$ = signal(false);

	async initialize(): Promise<void> {
		const queryParams = this.route.snapshot.queryParams;

		const hostedPaymentPageId = queryParams['id'];
		if (!hostedPaymentPageId) {
			console.error('Unable to find hosted payment page id in query params.');
			return;
		}

		const state = queryParams['state'];
		if (!state) {
			console.error('Unable to find state in query params.');
			return;
		}

		const request: IProcessHostedPaymentPageRequest = {
			hostedPaymentPageId,
			state,
		};

		await firstValueFrom(this.subscriptionService.processHostedPaymentPageResult(request));

		// a successful subscription will update user data. Reinitialize
		// the user store so that those componets that are subscribed to
		// the userInfo can refresh - the side nav menu, search box, etc
		await this.userStoreService.reinitialize();
	}
}
