<div>
	<div class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl p4">
		<div *ngIf="!submittedRequest; else sayThanks">
			<form [formGroup]="inviteInfo" class="items-left mx-auto flex max-w-4xl flex-col gap-4 text-2xl sm:text-3xl p-4">
				<mat-form-field class="w-full">
					<mat-label>Email Address</mat-label>
					<input matInput formControlName="email" />
				</mat-form-field>
				<div class="flex flex-row justify-end">
					<button mat-flat-button (click)="joinWaitList()" [disabled]="submittingRequest">Submit</button>
				</div>
			</form>
		</div>
		<ng-template #sayThanks>
			<div class="p-4">Thank you for registering! We will be in contact soon!</div>
		</ng-template>
	</div>
</div>
