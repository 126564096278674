import { Component, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import { openManageSubscriptionDialog } from '../../modules/instructor/components/subscriptions/manage-subscription-dialog/manage-subscription-dialog.component';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { UserStoreService } from '../../services/stores/user-store.service';

@Component({
	selector: 'app-gfx-pricing',
	templateUrl: './gfx-pricing.component.html',
	styleUrls: ['./gfx-pricing.component.scss'],
	standalone: true,
	imports: [MatButtonModule, MatCardModule, MatDividerModule, MatIconModule, RouterModule],
})
export class GfxPricingComponent implements OnInit {
	constructor(
		private userStoreService: UserStoreService,
		private dialog: MatDialog,
		private confettiService: ConfettiService,
	) {}

	isAuthenticated$ = signal(false);
	hasActiveSubscription$ = signal(false);
	hasActiveTrial$ = signal(false);
	trialEndDate$ = signal(undefined as string | undefined);

	ngOnInit(): void {
		this.initializeUserData();
	}

	initializeUserData(): void {
		const userData = this.userStoreService.userData$();

		this.isAuthenticated$.set(userData.isAuthenticated());
		this.hasActiveSubscription$.set(userData.hasActiveSubscription());
		this.hasActiveTrial$.set(userData.hasActiveSubscriptionOrTrial());
		const trialEndDate = userData.getUserData()?.getSubscriptionModel()?.getTrialEndDateUtc();
		if (trialEndDate) {
			this.trialEndDate$.set(DateTime.fromISO(trialEndDate).toLocaleString());
		}
	}

	async onChoosePlan(planFrequency: 'monthly' | 'yearly'): Promise<void> {
		const previousSubscriptionStatus = this.hasActiveSubscription$();
		const dialogRef = openManageSubscriptionDialog(this.dialog, planFrequency);
		dialogRef.afterClosed().subscribe(async () => {
			await this.userStoreService.reinitialize();
			this.initializeUserData();

			if (previousSubscriptionStatus !== this.hasActiveSubscription$()) {
				this.confettiService.celebrate();
			}
		});
	}
}
