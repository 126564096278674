<!-- Unauthenticated display -->
<div class="w-full h-14 bg-gfx-primary text-white p-1 flex items-center justify-center">
	@if (!userInfo$().isAuthenticated) {
		<div class="flex h-14 bg-gfx-primary text-white p-1 items-center justify-center">
			<span class="flex flex-row items-center">
				<a class="text-xs text-white hover:underline hover:cursor-pointer" [href]="marketingSiteUrl + '/terms'"
					>Terms</a
				>
				<span class="mx-1">|</span>
				<a class="text-xs text-white hover:underline hover:cursor-pointer" [href]="marketingSiteUrl + '/privacy'"
					>Privacy</a
				>
			</span>
		</div>
	} @else {
		<!-- Authenticated display -->

		<button mat-button routerLink="{{ getHomeRouterLink() }}">
			<div class="grid">
				<mat-icon class="text-white row-start-1 justify-self-center">home</mat-icon>
				<div class="text-white text-xs row-start-2 justify-self-center">Home</div>
				<div class="h-1" [@footerUnderline]="isLinkActive('/instructor/home') ? 'active' : 'inactive'"></div>
			</div>
		</button>
		<span class="mx-1">|</span>
		<button mat-button routerLink="/instructor/my-playlists">
			<div class="grid">
				<mat-icon class="text-white row-start-1 justify-self-center">featured_play_list</mat-icon>
				<div class="text-white text-xs row-start-2 justify-self-center">Playlists</div>
				<div class="h-1" [@footerUnderline]="isLinkActive('/instructor/my-playlists') ? 'active' : 'inactive'"></div>
			</div>
		</button>
		<span class="mx-1">|</span>
		<button mat-button routerLink="/instructor/search">
			<div class="grid">
				<mat-icon class="text-white row-start-1 justify-self-center">search</mat-icon>
				<div class="text-white text-xs row-start-2 justify-self-center">Search</div>
				<div class="h-1" [@footerUnderline]="isLinkActive('/instructor/search') ? 'active' : 'inactive'"></div>
			</div>
		</button>
		<span class="mx-1">|</span>
		<button mat-button routerLink="/instructor/releases">
			<div class="grid">
				<mat-icon class="text-white row-start-1 justify-self-center">assignment</mat-icon>
				<div class="text-white text-xs row-start-2 justify-self-center">Releases</div>
				<div class="h-1" [@footerUnderline]="isLinkActive('/instructor/releases') ? 'active' : 'inactive'"></div>
			</div>
		</button>
		<span class="mx-1">|</span>
		<button mat-button (click)="createPlaylist()">
			<div class="grid">
				<mat-icon class="text-white row-start-1 justify-self-center">list_alt_add</mat-icon>
				<div class="text-white text-xs row-start-2 justify-self-center">Create</div>
			</div>
		</button>
	}
</div>
