import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { WaitListService } from '../../services/waitlist/waitlist.service';

@Component({
	selector: 'app-wait-list',
	templateUrl: './wait-list.component.html',
	styleUrls: ['./wait-list.component.scss'],
})
export class WaitListComponent {
	submittingRequest: boolean = false;
	submittedRequest: boolean = false;
	inviteInfo = this._formBuilder.group({
		email: ['', Validators.required],
	});

	constructor(
		meta: Meta,
		private waitListService: WaitListService,
		private _formBuilder: FormBuilder,
	) {
		meta.updateTag({ property: 'og:title', content: 'Group Fitness X' });
		meta.updateTag({ property: 'og:description', content: 'Group Fitness Instructor Portal' });
		meta.updateTag({ property: 'og:image', content: `https://groupfitx.com/assets/images/gfx-heartbeat.webp` });
		meta.updateTag({ property: 'og:url', content: 'https://groupfitx.com' });
		meta.updateTag({ property: 'og:type', content: 'website' });
	}

	joinWaitList(): void {
		const emailAddress = this.inviteInfo.value.email;
		if (!emailAddress) {
			return;
		}

		this.submittingRequest = true;

		this.waitListService.joinWaitList(emailAddress).subscribe((result) => {
			if (result) {
				this.submittedRequest = true;
				this.submittingRequest = false;
			}
		});
	}
}
