<div class="flex flex-col gap-4">
	<div class="text-xl text-center">We're Sorry To See You Go!</div>
	<div class="text-center">Your account has been removed. We hope you return again soon!</div>
	<div class="text-center">
		If you have any suggestions for improvement, please contact us at
		<a class="text-gfx-primary underline" href="mailto:support@groupfitx.com">support&#64;groupfitx.com</a>.
	</div>
	<div class="flex justify-center">
		<button mat-flat-button (click)="returnToHome()">GFX Home</button>
	</div>
</div>
