import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-not-found',
	standalone: true,
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
	imports: [MatIconModule],
})
export class NotFoundComponent {}
