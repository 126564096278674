import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import {
	HTTP_INTERCEPTORS,
	withFetch,
	HttpRequest,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';

import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { GenericErrorDialogComponent } from './generic-error-dialog/generic-error-dialog.component';
import { AutocompleteMultiComponent } from './autocomplete-multi/autocomplete-multi.component';
import { LoginComponent } from './components/login/login.component';
import { ToolbarService } from './services/toolbar/toolbar.service';
import { WaitListService } from './services/waitlist/waitlist.service';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { AboutComponent } from './components/about/about.component';
import { InstructorReleasePlaylistService } from './services/instructor-release-playlist/instructor-release-playlist.service';
import { MyPlaylistService } from './services/my-playlist/my-playlist.service';
import { ProgramService } from './services/programs/program.service';
import { CountryService } from './services/countries/country.service';
import { StateService } from './services/states/states.service';
import { SubscriptionService } from './services/subscriptions/subscription.service';
import { BillingInfoComponent } from './modules/instructor/components/billing-info/billing-info.component';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ReleaseTrackService } from './services/release-track/release-track.service';
import { ProgramStoreService } from './services/stores/program-store.service';
import { ReleaseStoreService } from './services/stores/release-store.service';
import { ReleaseService } from './services/releases/release.service';
import { UserStoreService } from './services/stores/user-store.service';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ExerciseStoreService } from './services/stores/exercise-store.service';
import { ExerciseService } from './services/exercise/exercise.service';
import { RecentChangesComponent } from './components/recent-changes/recent-changes.component';
import { LuxonDateAdapter, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { ShareService } from './services/share/share.service';
import { ProfileCompletionService } from './services/profile-completion/profile-completion.service';
import { ConfettiService } from './services/confetti/confetti.service';
import { CanonicalLinkService } from './services/canonical-link/canonical-link.service';
import { ChoreographyService } from './services/choreography/choreography.service';
import { AnimatedPdfViewerComponent } from './components/animated-pdf-viewer/animated-pdf-viewer.component';
import { CreatePlaylistService } from './services/create-playlist/create-playlist.service';
import { environment } from '../environments/environment';
import { FooterComponent } from './components/footer/footer.component';
import { AccountRemovedComponent } from './components/account-removed/account-removed.component';

@NgModule({
	declarations: [
		AboutComponent,
		AccountRemovedComponent,
		AppComponent,
		AutocompleteMultiComponent,
		CreateAccountComponent,
		GenericErrorDialogComponent,
		FooterComponent,
		HomeComponent,
		LoginComponent,
		RecentChangesComponent,
		WaitListComponent,
	],
	bootstrap: [AppComponent],
	imports: [
		AnimatedPdfViewerComponent,
		BillingInfoComponent,
		BrowserModule,
		RouterModule.forRoot(AppRoutes),
		BrowserAnimationsModule,
		HeaderComponent,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDialogModule,
		MatDividerModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatLuxonDateModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTableModule,
		MatToolbarModule,
		MatTooltipModule,
		ReactiveFormsModule,
		ScrollingModule,
	],
	providers: [
		CanonicalLinkService,
		ChoreographyService,
		ConfettiService,
		CountryService,
		CreatePlaylistService,
		ExerciseService,
		ExerciseStoreService,
		InstructorReleasePlaylistService,
		MyPlaylistService,
		ProfileCompletionService,
		ProgramStoreService,
		ReleaseService,
		ReleaseStoreService,
		ReleaseTrackService,
		ShareService,
		StateService,
		SubscriptionService,
		ProgramService,
		ToolbarService,
		UserStoreService,
		WaitListService,
		{
			provide: 'API_URL',
			useValue: environment.API_URL,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
		{
			provide: DateAdapter<unknown>,
			useClass: LuxonDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		provideAnimations(),
		provideClientHydration(
			withHttpTransferCacheOptions({
				filter: (request: HttpRequest<unknown>) => {
					return !request.url.includes('/api/account/user');
				},
			}),
		),
		provideHttpClient(withFetch()),
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
