import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export class Animations {
	static page = [
		trigger('footerUnderline', [
			state(
				'inactive',
				style({
					borderTop: '2px solid white',
					width: '0px',
				}),
			),
			state(
				'active',
				style({
					borderTop: '2px solid white',
					width: '100%',
				}),
			),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),

		trigger('opacityAnimation', [
			transition('* => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
		]),

		trigger('slideIn', [
			state('close', style({ maxWidth: '0%', visibility: 'hidden' })),
			state('open', style({ maxWidth: '50%', visibility: 'visible' })),
			transition('close <=> open', [animate('.3s linear')]),
		]),

		trigger('slideOver', [
			state('close', style({ paddingRight: '0' })),
			state('open', style({ paddingRight: '50%' })),
			transition('close <=> open', [animate('.3s linear')]),
		]),

		trigger('slideOverDrawer', [
			state('close', style({ right: '-50%', visibility: 'hidden' })),
			state('open', style({ right: '0', visibility: 'visible' })),
			transition('close <=> open', [animate('150ms linear')]),
		]),

		trigger('slideAndFadeDrawer', [
			state('close', style({ right: '-50%', visibility: 'hidden', opacity: 0 })),
			state('open', style({ right: '0', visibility: 'visible', opacity: 1 })),
			transition('close => open', [animate('.3s ease-in')]),
			transition('open => close', [animate('.3s ease-out')]),
		]),

		trigger('listAnimation', [
			transition('* => *', [
				query(
					':enter',
					[
						style({
							opacity: 0,
							height: 0,
							transform: 'scale(0.85)',
							paddingTop: 0,
							paddingBottom: 0,
							paddingLeft: 0,
							paddingRight: 0,
						}),
						stagger(
							'60ms',
							animate(
								'200ms',
								style({
									opacity: 1,
									height: '*',
									paddingTop: '*',
									paddingBottom: '*',
									paddingLeft: '*',
									paddingRight: '*',
								}),
							),
						),
					],
					{
						optional: true,
					},
				),
			]),
		]),

		trigger('listAnimation1', [
			transition('* => *', [
				query(':enter', [style({ opacity: 0 }), stagger('60ms', animate('300ms ease-in', style({ opacity: 1 })))], {
					optional: true,
				}),
			]),
		]),
	];
}
