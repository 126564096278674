import { Injectable, signal } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ProfileCompletionService {
	public hasName$ = signal(false);
	public hasPrograms$ = signal(false);
	public hasProfilePicture$ = signal(false);
	public hasBio$ = signal(false);
	public hasCompletedProfile$ = signal(false);
}
