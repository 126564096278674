<div
	class="flex flex-col h-[100dvh] bg-cover bg-center bg-gradient-radial from-white to-zinc-200 dark:from-gfx-cardbg dark:to-gfx-bg-end-gradient">
	<div class="">
		<app-header></app-header>
	</div>
	<div class="flex-grow overflow-auto">
		@if (isLoading$() && !hasLoadingError$()) {
			<div class="w-full">
				<div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
				</div>
			</div>
		}
		@if (hasLoadingError$()) {
			<div>
				<p>An error occurred while loading the application data.</p>
			</div>
		}
		@if (!isLoading$()) {
			<router-outlet></router-outlet>
		}
	</div>
	<div class="app-footer">
		<app-footer></app-footer>
	</div>
</div>
