<div class="sm:max-w-xl lg:max-w-4xl mx-auto gap-4 mb-8">
	<h1 class="sm:text-xl lg:text-3xl font-bold text-gfx-primary dark:text-purple-400 text-center">About GFX</h1>
	<div class="m-16"></div>
	<section id="why" class="mt-16 mx-auto">
		<p class="m-4 text-4xl text-gfx-primary dark:text-purple-400 text-center font-bold">Why GFX Exists</p>
		<div class="border-2 border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4">
			<p class="text-center my-4">
				This website exists to help group fitness instructors plan their
				<span class="font-bold">class playlists</span> outside of quarterly releases and
				<span class="font-bold">share class playlists</span> with colleagues and gym members.
			</p>
			<p class="text-center my-4">
				GFX will help you find the releases, artists, songs, and exercises you are looking for so that you don't have to
				scan the back of a DVD case to find a song, or read through countless playlists on your device looking for an
				artist, or open every set of choreography notes to find whether a track has a particular exercise.
			</p>
		</div>
	</section>
	<section id="story" class="mt-16 mx-auto">
		<p class="m-4 text-4xl text-gfx-primary dark:text-purple-400 text-center font-bold">The Story Behind GFX</p>
		<div class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4">
			<p class="text-centermy-4">
				My wife has been a group fitness instructor in the Les Mills programs since 2007. She is certified in
				<span class="font-bold"
					>BODYATTACK™, BODYPUMP™, BODYCOMBAT™, BODYBALANCE™, LES MILLS GRIT™, and LES MILLS CORE™</span
				>. Aside from our three children, teaching her classes is her greatest passion.
			</p>
			<p class="text-center my-4">
				As a group fitness instructor in the Les Mills programs, every quarter you get new choreography. Typically the
				gyms and instructors coordinate to follow the same releases. It's a great opportunity to teach with colleagues
				and create opportunties to draw in more members.
			</p>
			<p class="text-center my-4">
				Aside from the quarterly releases, it's the responsibility of the group fitness instructors to mix up the class
				playlists. I noticed my wife opening up Les Mills DVD after DVD, or scanning releases on her Les Mills app to
				change out her choreography to keep the class fresh and engaging for the members.
			</p>
			<p class="text-center my-4">
				It occurred to me that there was no
				<span class="font-bold">centralized catalog</span> of this information. At least not a catalog that understood
				how instructors go about creating class playlists. In the absence of this catalog, instructors had to either
				manually review the music and choreography, or reach out to their groups on social media to figure out which
				release had a particular artist, certain song, or didn't have an exercise they wanted to avoid for a member.
			</p>
		</div>
	</section>
	<section id="thankyou" class="mt-16 mx-auto">
		<p class="m-4 text-4xl text-gfx-primary dark:text-purple-400 text-center font-bold">Thank You</p>
		<div class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4">
			<p class="text-center my-4">
				Group fitness instructors dedicate an incredible amount of their time to create a positive, motivating, and
				inclusive environment for gym members. I have witnessed this first-hand for over 15 years. I understand the
				dedication, sacrifice, and passion you all must have to do this service in the community. On behalf of myself
				and those that you have helped throughout their journeys,
				<span class="font-bold">thank you</span>.
			</p>
		</div>
	</section>
	<section id="join" class="max-w-2xl lg:max-w-xl mt-16 mx-auto">
		<p class="mb-6 text-4xl text-gfx-primary dark:text-purple-400 text-center font-bold sm:text-5xl">Join Us</p>
		<div>
			<div class="mb-8 text-center">
				GFX is just getting underway. If you're interested in seeing how we can help you plan your classes, join our
				wait list.
			</div>
			<app-wait-list></app-wait-list>
		</div>
	</section>
	<section id="contact" class="mt-16 mx-auto">
		<p class="m-4 text-4xl text-gfx-primary dark:text-purple-400 text-center font-bold">Contact Us</p>
		<div class="border-2 border-solid border-gfx-primary dark:border-gfx-accent rounded-xl mx-auto p-4">
			<p class="text-center my-4">
				If you have any questions, comments, or suggestions, please reach out to us at
				<a class="underline" href="mailto:support@groupfitx.com">support&#64;groupfitx.com</a>.
			</p>
		</div>
	</section>
	<div class="mt-6 max-w-sm mx-auto">
		<img
			class="block dark:hidden"
			src="../../assets/images/MS_Startups_Celebration_Badge_Light.webp"
			alt="Partner with Microsoft for Startups" />
		<img
			class="hidden dark:block"
			src="../../assets/images/MS_Startups_Celebration_Badge_Dark.webp"
			alt="Partner with Microsoft for Startups" />
	</div>
</div>
