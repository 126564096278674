import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppClientModule } from './app/app.module.client';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppClientModule)
	.then((success) => console.log('Bootstrap success'))
	.catch((err) => console.error(err));
